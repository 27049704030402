import { getTranslationByKey, translation } from '@/helpers/TranslationHelper';

export const renderAllergenName = (withPercentage, allergen, additionalAllergen) => {
    if (withPercentage) {
        if (allergen.isSpecific) {
            return translation('cart.otherAllergen', { percentage: allergen.percentage });
        }

        return translation('cart.withoutAllergen', {
            percentage: allergen.percentage,
            name: getTranslationByKey(allergen.name),
        });
    } else {
        if (allergen.isSpecific) {
            return translation('common.withoutAllergen', { allergen: additionalAllergen });
        }

        return translation('common.withoutAllergen', { allergen: getTranslationByKey(allergen.name) });
    }
};

import { getAdditionPrice, getMealKitAllPrices } from '@/helpers/CartHelper';
import { AllergenTypeEnum } from '@/utils/enums';
import { AllergenApi, AdditionApi } from '@/api';
import { renderAllergenName } from '@/helpers/AllergenHelper';

export const calculateMealKitPiceMixin = {
    props: {
        mealKit: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            renderAllergenName,
            getAdditionPrice,

            allergens: [],
            additions: [],

            form: {
                amount: 1,
                period: null,
                menuType: null,
                combination: null,
                calorie: null,
                mealKit: null,
                additions: [],
                allergens: [],
                additionalAllergen: '',
                showAllergenInput: false,
            },
        };
    },
    computed: {
        totalPrice() {
            return getMealKitAllPrices(this.form).totalPrice;
        },
    },
    methods: {
        setInitialState() {
            this.form = {
                amount: 1,
                period: !this.mealKit.isUnique ? this.mealKit.periods[0] : null,
                menuType: !this.mealKit.isUnique ? this.mealKit.menuTypes[0] : null,
                combination: !this.mealKit.isUnique ? this.mealKit.combinations[0] : null,
                calorie: !this.mealKit.isUnique ? this.mealKit.calories[0] : null,
                mealKit: this.mealKit,
                additions: [],
                allergens: [],
                additionalAllergen: '',
                showAllergenInput: false,
            };
        },

        checkIfObjectAddedInArray(state, item) {
            return this.form[state].some((elem) => elem.id === item.id);
        },

        async getData(Api, state, params = {}) {
            try {
                const response = await Api.getAll(params);
                this[state] = response.data.filter((item) => !item.isHidden);
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    },

    created() {
        this.setInitialState();
    },

    async mounted() {
        await this.getData(AllergenApi, 'allergens', { type: AllergenTypeEnum.ORDER });
        await this.getData(AdditionApi, 'additions');
    },
};

import { translation } from '@/helpers/TranslationHelper';

export const mealButtonsData = [
    { title: translation('common.breakfast'), value: 'breakfast' },
    { title: translation('common.snack_1'), value: 'snack_1' },
    { title: translation('common.lunch'), value: 'lunch' },
    { title: translation('common.snack_2'), value: 'snack_2' },
    { title: translation('common.dinner'), value: 'dinner' },
];

export const weekDaysButtonsData = [
    { title: translation('weekDays.mon'), value: 'monday' },
    { title: translation('weekDays.tue'), value: 'tuesday' },
    { title: translation('weekDays.wed'), value: 'wednesday' },
    { title: translation('weekDays.thu'), value: 'thursday' },
    { title: translation('weekDays.fri'), value: 'friday' },
];

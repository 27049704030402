import { mapActions, mapGetters } from 'vuex';

export const cartMixin = {
    computed: {
        ...mapGetters('cart', ['cart']),
    },

    methods: {
        ...mapActions('cart', ['toggleCart', 'toggleEditMode']),

        isAddedToCart(mealKitId) {
            return !!this.cart.find((item) => item.mealKit.id === mealKitId);
        },

        openCart(mealKit) {
            const data = {
                isOpened: true,
                mealKit,
            };

            this.toggleCart(data);
        },

        editCartItem(cartItem) {
            const data = {
                isOpened: true,
                editMode: {
                    isOpened: true,
                    cartItemId: cartItem.id,
                },
                mealKit: cartItem.mealKit,
            };

            this.toggleEditMode(data);
        },
    },
};

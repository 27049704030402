import { mapActions } from 'vuex';
import { ConversionEventNameEnum } from '@/utils/enums';

export const reorderROMixin = {
    methods: {
        ...mapActions('cart', ['addMealKitInCart']),

        async reorderRegularKit(order) {
            const form = {
                amount: order.amount,
                period: order.period,
                menuType: order.menuType,
                combination: order.combination,
                calorie: order.calorie,
                mealKit: order.mealKit,
                additions: order.additions,
                allergens: order.allergens,
                additionalAllergen: order.additionalAllergen,
                showAllergenInput: order.additionalAllergen ? true : false,
            };

            this.addMealKitInCart(form);
            this.$router.push({ name: 'checkOut' });

            fbq('track', ConversionEventNameEnum.INITIATE_CHECK_OUT);
        },
    },
};

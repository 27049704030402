import { mapMutations } from 'vuex';

export const individualOrderProgressMixin = {
    watch: {
        // Allergens
        'allergens.length': {
            handler(val) {
                if (val || this.order.additionalAllergen) {
                    this.updateProgress({ field: 'allergens', value: true });
                } else {
                    this.updateProgress({ field: 'allergens', value: false });
                }
            },
            immediate: true,
        },

        'order.additionalAllergen': {
            handler(val) {
                if (val || this.allergens.length) {
                    this.updateProgress({ field: 'allergens', value: true });
                } else {
                    this.updateProgress({ field: 'allergens', value: false });
                }
            },
            immediate: true,
        },

        // Disliked products
        'dislikedProducts.length': {
            handler(val) {
                if (val || this.order.additionalDislikedProduct) {
                    this.updateProgress({ field: 'dislikedProducts', value: true });
                } else {
                    this.updateProgress({ field: 'dislikedProducts', value: false });
                }
            },
            immediate: true,
        },

        'order.additionalDislikedProduct': {
            handler(val) {
                if (val || this.dislikedProducts.length) {
                    this.updateProgress({ field: 'dislikedProducts', value: true });
                } else {
                    this.updateProgress({ field: 'dislikedProducts', value: false });
                }
            },
            immediate: true,
        },

        // Macronutrients
        'order.macronutrients': {
            handler(val) {
                const objCopy = { ...val };
                delete objCopy.comment;

                const areTrue = Object.values(objCopy).every((value) => !!value);
                this.updateProgress({ field: 'macronutrients', value: areTrue });
            },
            immediate: true,
            deep: true,
        },

        // Diet plans
        'dietPlans.length': {
            handler(val) {
                if (val || this.order.additionalDietPlan.name) {
                    this.updateProgress({ field: 'dietPlans', value: true });
                } else {
                    this.updateProgress({ field: 'dietPlans', value: false });
                }
            },
            immediate: true,
        },

        'order.additionalDietPlan.name': {
            handler(val) {
                if (val || this.dietPlans.length) {
                    this.updateProgress({ field: 'dietPlans', value: true });
                } else {
                    this.updateProgress({ field: 'dietPlans', value: false });
                }
            },
            immediate: true,
        },

        // Meal Number
        'order.mealNumber': {
            handler(val) {
                const objCopy = { ...val };
                delete objCopy.comment;

                const areTrue = Object.values(objCopy).every((value) => !!value);
                this.updateProgress({ field: 'specificDishNumber', value: areTrue });
            },
            immediate: true,
            deep: true,
        },

        // Other requests
        'additions.length': {
            handler(val) {
                if (val || this.order.otherRequest) {
                    this.updateProgress({ field: 'otherRequests', value: true });
                } else {
                    this.updateProgress({ field: 'otherRequests', value: false });
                }
            },
            immediate: true,
        },

        'order.otherRequest': {
            handler(val) {
                if (val || this.additions.length) {
                    this.updateProgress({ field: 'otherRequests', value: true });
                } else {
                    this.updateProgress({ field: 'otherRequests', value: false });
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapMutations('individualOrder', ['updateProgress']),
    },
};

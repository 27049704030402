<template>
    <div class="order-card__container">
        <div class="section__wrapper">
            <h2 class="order-cart__title">
                {{ title }}
            </h2>
            <slot name="header" />
        </div>
        <div class="section__wrapper" v-if="$slots['body']">
            <slot name="body" />
        </div>
        <div class="section__wrapper">
            <CRButton pattern="thin" @click="$emit('clear')">
                {{ $t('order.clearSelection') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton';

    export default {
        name: 'OrderCard',
        components: { CRButton },
        props: {
            title: {
                type: String,
                default: '',
            },
        },
        emits: ['clear'],
    };
</script>

<style lang="scss" scoped>
    .order-card__container {
        border-radius: $br-10;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);

        .section__wrapper {
            padding: 20px;
            padding-bottom: 12px;

            border-bottom: solid $grey-line 1px;

            &:last-child {
                border-bottom: none;
            }
        }

        h2 {
            margin-bottom: 10px;
        }
    }
</style>

import { CheckOutStatusEnum } from '@/utils/enums';
import { mapActions } from 'vuex';

export const orderManagementSectionsMixin = {
    data() {
        return {
            isLoading: false,

            confirmationModalTitle: '',
            isConfirmationModalOpen: false,
            status: '',
        };
    },

    methods: {
        ...mapActions('orderManagement', ['updateCheckOut']),

        openConfirmationModal(status) {
            this.isConfirmationModalOpen = true;
            this.status = status;

            switch (status) {
                case CheckOutStatusEnum.DECLINED:
                    this.confirmationModalTitle = this.$t('orderManagement.cantProcessedModalTitle');
                    break;
                case CheckOutStatusEnum.ORDER_APPROVED:
                    this.confirmationModalTitle = this.$t('orderManagement.savePriceModalTitle');
                    break;
                case CheckOutStatusEnum.NOT_PAID:
                    this.confirmationModalTitle = this.$t('orderManagement.notPaidModalTitle');
                    break;
                case CheckOutStatusEnum.PAID:
                    this.confirmationModalTitle = this.$t('orderManagement.paidModalTitle');
                    break;
                case CheckOutStatusEnum.COMPLETED:
                    this.confirmationModalTitle = this.$t('orderManagement.completedModalTitle');
                    break;
                default:
                    this.confirmationModalTitle = '';
                    break;
            }
        },

        closeConfirmationModal() {
            this.isConfirmationModalOpen = false;
            this.confirmationModalTitle = '';
            this.status = '';
        },
    },
};

import { IndividualOrderStatusEnum, OrderStatusEnum } from '@/utils/enums';

export const kitManagementModalMixin = {
    data() {
        return {
            isLoading: false,

            confirmationOrderModalTitle: '',
            confirmationOrderModalDescription: '',
            isConfirmationOrderModalOpen: false,
            orderStatus: '',
        };
    },

    methods: {
        openOrderConfirmationModal(status, description = '') {
            this.isConfirmationOrderModalOpen = true;
            this.orderStatus = status;

            switch (status) {
                case IndividualOrderStatusEnum.CANCELLATION_REQUEST:
                    this.confirmationOrderModalTitle = this.$t('kitManagement.sendCancelationRequestModalTitle');
                    break;
                case IndividualOrderStatusEnum.DECLINED:
                    this.confirmationOrderModalTitle = this.$t('kitManagement.cancelOrderModalTitle');
                    break;
                case IndividualOrderStatusEnum.PAID:
                    this.confirmationOrderModalTitle = this.$t('kitManagement.continueOrderModalTitle');
                    break;
                case OrderStatusEnum.CANCELED_BY_USER:
                    this.confirmationOrderModalTitle = this.$t('kitManagement.cancelOrderModalTitle');
                    this.confirmationOrderModalDescription = this.$t('kitManagement.cancelOrderModalDescription', {
                        amount: description,
                    });
                    break;
                case OrderStatusEnum.CANCELED_BY_ADMIN:
                    this.confirmationOrderModalTitle = this.$t('kitManagement.cancelOrderModalTitle');
                    break;
                default:
                    this.confirmationOrderModalTitle = '';
                    break;
            }
        },

        closeOrderConfirmationModal() {
            this.isConfirmationOrderModalOpen = false;
            this.confirmationOrderModalTitle = '';
            this.orderStatus = '';
        },
    },
};

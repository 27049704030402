import LangButtons from '@/components/Common/Buttons/LangButtons.vue';

export const langMixin = {
    components: { LangButtons },
    data() {
        return {
            locale: 'lt',
        };
    },
    watch: {
        locale() {
            if (this.errors) {
                Object.entries(this.errors).forEach(([key]) => {
                    this.errors[key] = [];
                });
            }
        },
    },
    methods: {
        selectLocale(locale) {
            this.locale = locale;
        },
    },
};

<template>
    <div class="spin__wrapper">
        <button
            class="minus"
            @click="decreaseNumber"
            @mousedown="whileMouseDown(decreaseNumber)"
            @mouseup="clearTimer"
            :class="buttonClass"
            :disabled="disabled"
        >
            <MinusSvg />
        </button>
        <input
            type="number"
            v-bind:value="numericValue"
            @keypress="validateInput"
            @input="inputValue"
            :class="inputClass"
            :min="min"
            :max="max"
            debounce="500"
            :disabled="disabled"
        />
        <button
            class="plus"
            @click="increaseNumber"
            @mousedown="whileMouseDown(increaseNumber)"
            @mouseup="clearTimer"
            :class="buttonClass"
            :disabled="disabled"
        >
            <PlusSvg />
        </button>
    </div>
</template>

<script>
    import MinusSvg from '@/assets/icons/buttons/minus-black.svg?inline';
    import PlusSvg from '@/assets/icons/buttons/plus-white.svg?inline';

    export default {
        name: 'CRSpin',
        components: { PlusSvg, MinusSvg },
        props: {
            modelValue: {
                type: Number,
                default: 0,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: 100,
            },
            step: {
                type: Number,
                default: 1,
            },
            mouseDownSpeed: {
                type: Number,
                default: 100,
            },
            inputClass: {
                type: String,
                default: '',
            },
            buttonClass: {
                type: String,
                default: '',
            },
            integerOnly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                numericValue: this.modelValue,
                timer: null,
            };
        },
        watch: {
            numericValue: function (val, oldVal) {
                if (val <= this.min) {
                    this.numericValue = parseInt(this.min);
                }
                if (val >= this.max) {
                    this.numericValue = parseInt(this.max);
                }
                if (val <= this.max && val >= this.min) {
                    this.$emit('update:modelValue', val, oldVal);
                }
            },

            modelValue(val) {
                this.numericValue = val;
            },
        },
        methods: {
            clearTimer() {
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            },
            whileMouseDown(callback) {
                if (this.timer === null) {
                    this.timer = setInterval(() => {
                        callback();
                    }, this.mouseDownSpeed);
                }
            },
            increaseNumber() {
                this.numericValue += this.step;
            },
            decreaseNumber() {
                this.numericValue -= this.step;
            },
            isInteger(evt) {
                evt = evt ? evt : window.event;
                let key = evt.keyCode || evt.which;
                key = String.fromCharCode(key);
                const regex = /[0-9]/;
                if (!regex.test(key)) {
                    evt.returnValue = false;
                    if (evt.preventDefault) evt.preventDefault();
                }
            },
            isNumber(evt) {
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            validateInput(evt) {
                if (this.integerOnly === true) {
                    this.isInteger(evt);
                } else {
                    this.isNumber(evt);
                }
            },
            inputValue(evt) {
                this.numericValue = evt.target.value ? parseInt(evt.target.value) : this.min;
                this.$emit('update:modelValue', this.numericValue);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .spin__wrapper {
        display: flex;
        height: 45px;

        border: 2px solid $grey;
        border-radius: $br-5;

        background: $white;
        opacity: 1;

        button {
            @include row-align-center-justify-center;

            cursor: pointer;
            background: none;
            border: none;
            padding: 10px 15px;
        }

        .minus {
            border-right: 2px solid $grey;
        }

        .plus {
            border-left: 2px solid $grey;
        }

        input {
            width: 100%;
            background: none;
            border: none;
            text-align: center;
            outline: none;
            font-weight: 500;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    :deep(svg) {
        path {
            fill: $black;
        }
    }
</style>

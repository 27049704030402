import { TYPE } from 'vue-toastification';
import { CRUDTypeEnum } from '@/utils/enums';

export const toasterMixin = {
    data() {
        return {
            CRUDTypeEnum,
            TYPE,
        };
    },
    methods: {
        notify(entityName, operationName = this.CRUDTypeEnum.CREATED, notificationType = this.TYPE.SUCCESS) {
            this.$filters.toast(
                this.$filters.getTranslationByKey(entityName) + this.$t(`otherSettings.${operationName}`),
                { type: this.TYPE[`${notificationType}`.toUpperCase()] }
            );
        },
    },
};

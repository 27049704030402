export const rerenderMixin = {
    data() {
        return {
            componentKey: 0,
        };
    },
    watch: {
        '$i18n.locale'() {
            this.forceRerender();
        },
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
    },
};

import { MenuTypeEnum, WeekTypeEnum } from '@/utils/enums';
import { weekDaysButtonsData } from '@/config/menus/menus';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { EvaluateDishSettingApi } from '@/api';
import { throttle } from 'lodash';

export const weeksMenuMixin = {
    inject: ['provideApp'],
    data() {
        return {
            weekDaysButtonsData,

            isRatingModalOpened: false,
            isAllergensShown: false,
            isCaloriesShown: false,

            menuType: MenuTypeEnum.REGULAR,
            selectedCombinationId: 0,
            selectedCombination: null,
            selectedWeekDays: ['monday'],

            evaluateDishSetting: null,

            editedMenu: null,
        };
    },
    computed: {
        ...mapGetters('menu', ['currentWeekGroupedMenu']),

        menusList() {
            return Object.entries(this.currentWeekGroupedMenu[this.menuType] ?? {});
        },
    },
    watch: {
        selectedCombinationId(value) {
            this.selectedCombination = this.combinations.find((item) => item.id === value);
        },
    },
    methods: {
        ...mapActions('menu', ['groupedWeekMenu']),
        ...mapMutations('menu', ['syncDishWithMenu']),

        handelSelectMenuType: throttle(async function (value) {
            this.menuType = value;
            await this.loadMenuByType(value);
        }, 500),

        async loadMenuByType(type) {
            try {
                await this.groupedWeekMenu({ type, weekType: WeekTypeEnum.CURRENT });
                this.selectedCombinationId = this.combinations[0]?.id;
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },

        handelToggleWeekDay(value) {
            if (this.provideApp.$screenInnerWidth <= 1024) {
                this.selectedWeekDays = [value];
            } else {
                if (this.selectedWeekDays.length === 2 && !this.selectedWeekDays.includes(value)) {
                    this.selectedWeekDays.shift();
                }

                if (this.selectedWeekDays.includes(value) && this.selectedWeekDays.length !== 1) {
                    this.selectedWeekDays = this.selectedWeekDays.filter((itemId) => itemId !== value);
                } else {
                    this.selectedWeekDays.push(value);
                }
            }
        },

        // rating
        openRatingModal(menu) {
            this.editedMenu = menu;
            this.isRatingModalOpened = true;
        },

        updateDishRating(dish) {
            if (!this.isUnique) {
                this.syncDishWithMenu({ dish, menu: this.editedMenu });
            } else {
                this.editedMenu.dish = dish;
            }

            this.editedMenu = null;
        },

        getInitialWeekday() {
            const currentDate = new Date();

            const currentWeekday = currentDate.toLocaleString('default', { weekday: 'long' }).toLowerCase();
            const nextWeekday = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
                .toLocaleString('default', { weekday: 'long' })
                .toLowerCase();

            if (currentWeekday !== 'sunday' || currentWeekday !== 'saturday') {
                this.selectedWeekDays = [currentWeekday];
            }

            if (this.provideApp.$screenInnerWidth >= 1024) {
                if (nextWeekday === 'sunday' || nextWeekday === 'saturday') {
                    this.selectedWeekDays.push('monday');
                } else {
                    this.selectedWeekDays.push(nextWeekday);
                }
            }
        },

        async getEvaluateDishSetting() {
            try {
                const response = await EvaluateDishSettingApi.get();
                this.evaluateDishSetting = response.data;
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    },

    async mounted() {
        if (!this.isUnique) {
            await this.loadMenuByType(this.menuType);

            this.getInitialWeekday();
        }

        await this.getEvaluateDishSetting();
    },
};

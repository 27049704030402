import { mapActions, mapGetters } from 'vuex';
import { ConversionEventNameEnum } from '@/utils/enums';

export const openMealKitPageMixin = {
    computed: {
        ...mapGetters('mealKit', ['allMealKitsWithIO']),
    },
    methods: {
        ...mapActions('mealKit', ['getAllMealKitsWithIO']),

        async openMealKitPage(mealKit) {
            if (!mealKit.isIndividual) {
                this.$router.push({
                    name: 'mealKit',
                    params: { meal_kit_id: mealKit.id },
                });
            } else {
                this.$router.push({
                    name: 'individualOrderKit',
                });
            }     
            
            fbq('track', ConversionEventNameEnum.VIEW_CONTENT, {
                content_ids: [mealKit.id],
                content_name: mealKit.name,
                content_type: 'product'
            });
        },
    },
    async created() {
        try {
            await this.getAllMealKitsWithIO();
        } catch (error) {
            this.$filters.toast(error.message);
        }
    },
};

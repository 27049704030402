<template>
    <div class="order-kit__wrapper" v-if="individualSettings" ref="wrapper">
        <h1 class="title">{{ $t('order.individualOrder') }}</h1>
        <!-- Menu Type select -->
        <div class="order-kit__section">
            <h3>{{ $t('order.typeSelection') }}</h3>
            <div class="order-kit__section__buttons-container">
                <CRButton
                    class="btn"
                    v-for="menuType in menuTypes"
                    :key="menuType.id"
                    size="small"
                    :active="order.menuType?.id === menuType.id"
                    pattern="section"
                    @click="handelSelectClick(menuType, 'menuType')"
                >
                    {{ $t(`common.${menuType.type}`) }}
                </CRButton>
            </div>
        </div>

        <!-- Kit type select and personal menu -->
        <div class="order-kit__section kit-personal-menu__section">
            <h3>{{ $t('order.kitSelection') }}</h3>
            <div class="order-kit__section__buttons-container" v-if="individualSettings.withPersonalKit">
                <CRButton
                    class="btn"
                    v-for="mealKit in shownRegularKits"
                    :key="mealKit.id"
                    pattern="section"
                    size="x-small"
                    :active="isMealKitShouldBeActive(mealKit.id)"
                    @click="handleMealKitSelect(mealKit)"
                >
                    {{ $filters.getTranslationByKey(mealKit.name) }}
                </CRButton>
            </div>
            <div class="order-kit__section__buttons-container">
                <CRButton
                    class="btn personal-menu"
                    pattern="section"
                    size="x-small"
                    :active="isPersonalMenuChosen"
                    @click="handlePersonalMenuClick"
                >
                    {{ $t('order.personalMenu') }}
                </CRButton>
            </div>

            <div v-if="individualSettings.withPersonalKit && !isPersonalMenuChosen">
                <CRRadio
                    class="radio"
                    v-for="calorie in order.orderableItem.calories"
                    :key="calorie.id"
                    v-model="order.caloriesAmount"
                    :value="calorie.amount"
                >
                    {{ calorie.amount }} {{ $t('common.kcal') }}
                </CRRadio>
            </div>

            <!-- Personal menu -->
            <div class="file-upload order-kit__section" v-if="isPersonalMenuChosen">
                <div v-if="isUploadBlockVisible">
                    <h5 class="mb-30">
                        {{ $t('order.personalMenuDescription') }}
                    </h5>
                    <label class="upload-input">
                        <FileUploadSVG class="file-icon" />
                        <input
                            class="file-input"
                            type="file"
                            ref="file"
                            @change="handleFileUpload($event)"
                            accept="application/pdf"
                        />
                        <CRButton pattern="thin">
                            <input
                                class="file-input"
                                type="file"
                                @change="handleFileUpload($event)"
                                accept="application/pdf"
                            />
                            {{
                                personalMenu.file || personalMenuState?.filePath
                                    ? $t('buttons.changeFile')
                                    : $t('buttons.addFile')
                            }}
                        </CRButton>
                    </label>
                    <CRTag
                        class="tag tag-link my-15"
                        svgColor="#00595A"
                        type="additional"
                        icon="document-filled"
                        v-if="personalMenuState?.filePath || personalMenu.file"
                        delete-allowed
                        @delete="deletePersonalMenuFile"
                    >
                        <a :href="personalMenuState?.filePath" target="_blank" class="underscored-link">
                            {{ $t('individualOrder.menu') }}
                        </a>
                    </CRTag>
                    <CRInput
                        class="input"
                        :label="$t('individualOrder.link')"
                        :placeholder="$t('individualOrder.link')"
                        :errors="personalMenuErrors?.link"
                        @onChange="$filters.clearError(personalMenuErrors, 'link')"
                        v-model="personalMenu.link"
                    >
                    </CRInput>
                    <CRTextarea
                        class="input"
                        :label="$t('common.descriptionExtraInformation')"
                        :placeholder="$t('common.descriptionExtraInformation')"
                        :errors="personalMenuErrors?.extraInformation"
                        :height="350"
                        @onChange="$filters.clearError(personalMenuErrors, 'extraInformation')"
                        v-model="personalMenu.extraInformation"
                    >
                    </CRTextarea>
                </div>
                <div class="tag__wrapper" v-else>
                    <CRTag
                        class="tag"
                        svgColor="#00595A"
                        type="additional"
                        icon="document-filled"
                        v-if="personalMenuState?.filePath"
                    >
                        <a :href="personalMenuState.filePath" target="_blank" class="underscored-link">
                            {{ $t('individualOrder.menu') }}
                        </a>
                    </CRTag>
                    <CRTag class="tag" svgColor="#00595A" type="additional" icon="web" v-if="personalMenuState?.link">
                        <a :href="personalMenuState.link" target="_blank" class="underscored-link">
                            {{ personalMenuState.link }}
                        </a>
                    </CRTag>
                    <CRTag class="tag" type="additional" v-if="personalMenuState?.extraInformation">
                        {{ personalMenuState.extraInformation }}
                    </CRTag>
                </div>
            </div>
            <div class="personal-menu__buttons" v-if="order.orderableType === IndividualOrderableEnum.PERSONAL_MENU">
                <CRButton
                    v-if="isPersonalMenuChosen && isUploadBlockVisible"
                    size="small"
                    @click="savePersonalMenu"
                    :loading="isPersonalMenuLoading"
                    :disabled="isSavePersonalMenuBtnDisabled"
                >
                    {{ $t('buttons.save') }}
                </CRButton>
                <CRButton v-else size="small" @click="isUploadBlockVisible = true">
                    {{ $t('buttons.update') }}
                </CRButton>
            </div>
            <CRInput
                class="input"
                :label="$t('common.calorieNorm')"
                :placeholder="$t('common.calorieNorm')"
                v-model.number="order.caloriesAmount"
                type="number"
            />
        </div>

        <!-- Order terms -->
        <div class="order-kit__section">
            <h3>{{ $t('order.orderTerm') }}</h3>
            <CRRadio
                class="radio"
                v-for="period in individualSettings.periods"
                :key="period.id"
                :value="period"
                v-model="order.period"
            >
                {{ $filters.getTranslationByKey(period.name) }}
            </CRRadio>
        </div>

        <!-- Number of kits -->
        <div class="order-kit__section spin_section">
            <h3>{{ $t('order.numberOfKits') }}</h3>
            <CRSpin class="spin" :min="1" v-model="order.kitsAmount" />
        </div>

        <h2>{{ $t('order.individualRequests') }}</h2>

        <slot name="sideNav" />

        <!-- Allergens -->
        <div class="order-kit__section" id="allergens" v-if="individualSettings.withAllergens">
            <OrderCard :title="$t('common.allergens')" @clear="clearSection('allergens')">
                <template v-slot:header>
                    <div class="order-card__tag-container">
                        <CRButton
                            class="btn"
                            v-for="allergen in individualSettings.allergens"
                            :key="allergen.id"
                            :active="checkIfObjectAddedInArray('allergens', allergen)"
                            @click="handelToggle('allergens', allergen)"
                            pattern="toggle"
                        >
                            <template #icon>
                                <VegetarianSvg />
                            </template>
                            {{ $filters.getTranslationByKey(allergen.name) }}
                        </CRButton>
                    </div>
                </template>
                <template v-slot:body>
                    <CRInput
                        :placeholder="$t('order.cantFindInList')"
                        :label="$t('order.cantFindInList')"
                        v-model="order.additionalAllergen"
                    />
                </template>
            </OrderCard>
        </div>

        <!-- Disliked products -->
        <div class="order-kit__section" id="dislikedProducts" v-if="individualSettings.withDislikedProducts">
            <OrderCard :title="$t('navbar.dislikedProducts')" @clear="clearSection('dislikedProducts')">
                <template v-slot:header>
                    <div class="order-card__tag-container">
                        <CRButton
                            class="btn"
                            v-for="dislikedProduct in individualSettings.dislikedProducts"
                            :key="dislikedProduct.id"
                            :active="checkIfObjectAddedInArray('dislikedProducts', dislikedProduct)"
                            @click="handelToggle('dislikedProducts', dislikedProduct)"
                            pattern="toggle"
                        >
                            <template #icon>
                                <VegetarianSvg />
                            </template>
                            {{ $filters.getTranslationByKey(dislikedProduct.name) }}
                        </CRButton>
                    </div>
                </template>
                <template v-slot:body>
                    <CRInput
                        :placeholder="$t('order.cantFindInList')"
                        :label="$t('order.cantFindInList')"
                        v-model="order.additionalDislikedProduct"
                    />
                </template>
            </OrderCard>
        </div>

        <!-- Macronutrients -->
        <div class="order-kit__section" id="macronutrients" v-if="individualSettings.withMacronutrients">
            <OrderCard :title="$t('navbar.macronutrients')" @clear="clearSection('macronutrients')">
                <template v-slot:header>
                    <h5>
                        {{ $t('order.macronutrientTitle') }}
                    </h5>
                </template>
                <template v-slot:body>
                    <CRInput
                        class="input"
                        :placeholder="$t('order.proteinsGram')"
                        :label="$t('order.proteinsGram')"
                        v-model.number="order.macronutrients.proteins"
                        type="number"
                    />
                    <CRInput
                        class="input"
                        :placeholder="$t('order.fatsGram')"
                        :label="$t('order.fatsGram')"
                        v-model.number="order.macronutrients.fats"
                        type="number"
                    />
                    <CRInput
                        class="input"
                        :placeholder="$t('order.carbohydratesGram')"
                        :label="$t('order.carbohydratesGram')"
                        v-model.number="order.macronutrients.carbohydrates"
                        type="number"
                    />
                    <CRTextarea
                        class="input"
                        :placeholder="$t('order.comments')"
                        :label="$t('order.comments')"
                        v-model="order.macronutrients.comment"
                    />
                </template>
            </OrderCard>
        </div>

        <!-- Diet plans -->
        <div class="order-kit__section" id="dietPlans" v-if="individualSettings.withDietPlans">
            <OrderCard :title="$t('individualOrder.dietPlans')" @clear="clearSection('dietPlans')">
                <template v-slot:header>
                    <div class="order-card__tag-container">
                        <CRButton
                            class="btn"
                            v-for="dietPlan in individualSettings.dietPlans"
                            :key="dietPlan.id"
                            :active="checkIfObjectAddedInArray('dietPlans', dietPlan)"
                            @click="handelToggle('dietPlans', dietPlan)"
                            pattern="toggle"
                        >
                            <template #icon>
                                <VegetarianSvg />
                            </template>
                            {{ $filters.getTranslationByKey(dietPlan.name) }}
                        </CRButton>
                    </div>
                </template>
                <template v-slot:body>
                    <CRInput
                        class="input"
                        :placeholder="$t('order.otherDietPlan')"
                        :label="$t('order.otherDietPlan')"
                        v-model="order.additionalDietPlan.name"
                    />
                    <CRTextarea
                        class="input"
                        :placeholder="$t('order.comments')"
                        :label="$t('order.comments')"
                        v-model="order.additionalDietPlan.comment"
                    />
                </template>
            </OrderCard>
        </div>

        <!-- Specific dish number -->
        <div class="order-kit__section" id="specificDishNumber" v-if="individualSettings.withMealNumber">
            <OrderCard :title="$t('order.specificDishNumber')" @clear="clearSection('mealNumber')">
                <template v-slot:header>
                    <CRSpin class="spin" v-model="order.mealNumber.amount" />
                    <CRTextarea
                        class="input"
                        :placeholder="$t('order.comments')"
                        :label="$t('order.comments')"
                        v-model="order.mealNumber.comment"
                    />
                </template>
            </OrderCard>
        </div>

        <!-- Other requests -->
        <div class="order-kit__section" id="otherRequests">
            <OrderCard :title="$t('order.otherRequests')" @clear="clearSection('otherRequests')">
                <template v-slot:header>
                    <CRTextarea
                        :placeholder="$t('order.comments')"
                        :label="$t('order.comments')"
                        v-model="order.otherRequest"
                    />
                    <div class="additions__footer">
                        <h3>{{ $t('order.extraAdditions') }}</h3>
                        <CRCheckbox
                            class="radio"
                            v-for="addition in individualSettings.additions"
                            :key="addition.id"
                            :value="addition.id"
                            :checked="checkIfObjectAddedInArray('additions', addition)"
                            @change="handelToggle('additions', addition)"
                            is-box
                        >
                            <template #content>
                                <div class="addition__inner">
                                    <div class="content">
                                        <h6 class="name">
                                            {{ $filters.getTranslationByKey(addition.name) }}
                                        </h6>
                                        <p>{{ $t('cart.forWorkingDays', { amount: 1 }) }}</p>
                                        <h6>{{ addition.price }}€</h6>
                                    </div>
                                    <div class="img" :style="backgroundStyles(addition.picture?.picturePath)" />
                                </div>
                            </template>
                        </CRCheckbox>
                    </div>
                </template>
            </OrderCard>
        </div>

        <!-- Reset all section button -->
        <div class="order-kit__section">
            <CRButton pattern="outline" @click="handleReset">
                {{ $t('order.resetAllSelections') }}
            </CRButton>
        </div>

        <!-- Delivery button -->
        <div class="order-kit__section footer-btn__wrapper">
            <CRButton :disabled="isDeliveryBtnDisabled" @click="setIndividualOrderData">
                {{ $t('order.delivery') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import { backgroundStyles } from '@/helpers/CssHelper';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRRadio from '@/components/Common/Radio/CRRadio';
    import CRTag from '@/components/Common/Tags/CRTag';
    import CRSpin from '@/components/Common/Inputs/CRSpin';
    import OrderCard from '@/components/Order/Individual/OrderCard';
    import CRTextarea from '@/components/Common/Textarea/CRTextarea';
    import FileUploadSVG from '@/assets/icons/buttons/fileUpload.svg?inline';
    import VegetarianSvg from '@/assets/icons/tags/vegetarian.svg?inline';
    import { mapActions, mapGetters } from 'vuex';
    import { MenuTypeApi } from '@/api';
    import { IndividualOrderableEnum } from '@/utils/enums';
    import { individualOrderProgressMixin } from '@/utils/mixins';
    import { TYPE } from 'vue-toastification';
    import { cloneDeep } from 'lodash';

    const DEFAULT_PERSONAL_MENU_FORM = {
        file: null,
        link: '',
        extraInformation: '',
    };

    export const MACRONUTRIENTS_FORM = {
        proteins: '',
        fats: '',
        carbohydrates: '',
        comment: '',
    };

    export const MEAL_NUMBER_FORM = {
        amount: 0,
        comment: '',
    };

    export const ADDITION_DIET_PLAN_FORM = {
        name: '',
        comment: '',
    };

    const DEFAULT_ORDER_FORM = {
        // common
        period: null,
        menuType: null,
        caloriesAmount: '',
        kitsAmount: 1,
        additionalAllergen: '',
        additionalDislikedProduct: '',
        otherRequest: '',
        orderableType: IndividualOrderableEnum.PERSONAL_MENU,
        orderableItem: null,

        // macronutrients
        macronutrients: { ...MACRONUTRIENTS_FORM },

        //  Additional Diet plan
        additionalDietPlan: { ...ADDITION_DIET_PLAN_FORM },

        // Meal number
        mealNumber: { ...MEAL_NUMBER_FORM },
    };

    export default {
        name: 'IndividualOrderKit',
        components: {
            CRTextarea,
            CRRadio,
            CRInput,
            CRButton,
            CRTag,
            FileUploadSVG,
            CRSpin,
            OrderCard,
            VegetarianSvg,
            CRCheckbox,
        },
        props: {
            currentLink: {
                type: Object,
                default: () => {},
            },
            individualSettings: {
                type: Object,
                default: () => {},
            },
        },
        mixins: [individualOrderProgressMixin],
        inject: ['provideApp'],
        data() {
            return {
                IndividualOrderableEnum,
                backgroundStyles,

                menuTypes: [],
                isPersonalMenuChosen: true,
                isUploadBlockVisible: true,
                personalMenuErrors: {},
                isPersonalMenuLoading: false,

                // data for request
                order: cloneDeep(DEFAULT_ORDER_FORM),
                personalMenu: { ...DEFAULT_PERSONAL_MENU_FORM },
                allergens: [],
                additions: [],
                dislikedProducts: [],
                dietPlans: [],
            };
        },
        computed: {
            ...mapGetters('mealKit', ['shownRegularKits']),
            ...mapGetters('individualOrder', [
                'orderState',
                'personalMenuState',
                'personalMenuState',
                'allSelectedAdditionsState',
            ]),

            isSavePersonalMenuBtnDisabled() {
                return (
                    !this.personalMenu?.file &&
                    !this.personalMenu?.filePath &&
                    !this.personalMenu.link &&
                    !this.personalMenu.extraInformation
                );
            },

            isDeliveryBtnDisabled() {
                const orderableType = this.order.orderableType === IndividualOrderableEnum.PERSONAL_MENU ? this.personalMenuState : true;
                console.log(orderableType);
                return !orderableType;
            },
        },
        watch: {
            orderState: {
                handler(data) {
                    if (data) {
                        this.order = data;

                        if (this.order.orderableType === IndividualOrderableEnum.PERSONAL_MENU) {
                            this.isPersonalMenuChosen = true;
                        } else {
                            this.isPersonalMenuChosen = false;
                        }
                    }
                },
                immediate: true,
            },

            personalMenuState: {
                handler(data) {
                    if (data) {
                        this.personalMenu = data;
                    }
                },
                immediate: true,
            },

            allSelectedAdditionsState: {
                handler(data) {
                    if (data) {
                        this.allergens = this.allSelectedAdditionsState.allergens;
                        this.additions = this.allSelectedAdditionsState.additions;
                        this.dislikedProducts = this.allSelectedAdditionsState.dislikedProducts;
                        this.dietPlans = this.allSelectedAdditionsState.dietPlans;
                    }
                },
                immediate: true,
            },

            'currentLink.name': {
                handler(val) {
                    document.getElementById(val).scrollIntoView({
                        behavior: 'smooth',
                        block: this.provideApp.$screenInnerWidth < 1024 ? 'end' : 'start',
                    });
                },
            },
        },

        methods: {
            ...mapActions('individualOrder', [
                'setWholeIndividualOrder',
                'storePersonalMenu',
                'updatePersonalMenu',
                'setIndividualOrder',
                'destroyPersonalMenu',
                'destroyPersonalMenuFile',
            ]),

            checkIfObjectAddedInArray(state, item) {
                return this[state].some((elem) => elem.id === item.id);
            },

            setIndividualOrderData() {
                const payload = {
                    order: this.order,
                    personalMenu:
                        this.order.orderableType === IndividualOrderableEnum.PERSONAL_MENU ? this.personalMenu : null,
                    allSelectedAdditionsState: {
                        allergens: this.allergens,
                        additions: this.additions,
                        dislikedProducts: this.dislikedProducts,
                        dietPlans: this.dietPlans,
                    },
                };

                this.setWholeIndividualOrder(payload);

                this.$router.push({ name: 'individualOrderDelivery' });
            },

            async handleReset() {
                this.order = cloneDeep(DEFAULT_ORDER_FORM);
                this.order.menuType = this.menuTypes[0];
                this.order.period = this.individualSettings.periods[0];
                this.personalMenu = { ...DEFAULT_PERSONAL_MENU_FORM };

                this.allergens = [];
                this.additions = [];
                this.dislikedProducts = [];
                this.dietPlans = [];

                this.isPersonalMenuChosen = true;
                this.isUploadBlockVisible = true;

                if (this.personalMenuState) {
                    try {
                        await this.destroyPersonalMenu(this.personalMenuState.id);
                    } catch (error) {
                        this.$filters.toast(error.message);
                    }
                }

                const payload = {
                    order: null,
                    personalMenu: null,
                    allSelectedAdditionsState: null,
                };

                this.setWholeIndividualOrder(payload);
            },

            clearSection(sectionName) {
                if (sectionName === 'allergens') {
                    this.allergens = [];
                    this.order.additionalAllergen = '';
                }

                if (sectionName === 'dislikedProducts') {
                    this.dislikedProducts = [];
                    this.order.additionalDislikedProduct = '';
                }

                if (sectionName === 'macronutrients') {
                    this.order.macronutrients = { ...MACRONUTRIENTS_FORM };
                }

                if (sectionName === 'dietPlans') {
                    this.dietPlans = [];
                    this.order.additionalDietPlan = { ...ADDITION_DIET_PLAN_FORM };
                }

                if (sectionName === 'mealNumber') {
                    this.order.mealNumber = { ...MEAL_NUMBER_FORM };
                }

                if (sectionName === 'otherRequests') {
                    this.additions = [];
                    this.order.otherRequest = '';
                }
            },

            handelSelectClick(value, field) {
                this.order[field] = value;
            },

            handelToggle(field, value) {
                if (this.checkIfObjectAddedInArray(field, value)) {
                    this[field] = this[field].filter((item) => item.id !== value.id);
                } else {
                    this[field].push(value);
                }
            },

            async handleMealKitSelect(mealKit) {
                this.order.orderableType = IndividualOrderableEnum.MEAL_KIT;
                this.order.orderableItem = mealKit;
                this.order.caloriesAmount = this.order.orderableItem.calories[0].amount;
                this.isPersonalMenuChosen = false;
                this.isUploadBlockVisible = false;

                if (this.personalMenuState) {
                    try {
                        await this.destroyPersonalMenu(this.personalMenuState.id);
                    } catch (error) {
                        this.$filters.toast(error.message);
                    }
                }
            },

            handlePersonalMenuClick() {
                this.order.orderableType = IndividualOrderableEnum.PERSONAL_MENU;
                this.order.orderableItem = null;
                this.order.caloriesAmount = 0;
                this.isPersonalMenuChosen = true;
                this.isUploadBlockVisible = true;

                this.personalMenu = { ...DEFAULT_PERSONAL_MENU_FORM };
            },

            async deletePersonalMenuFile() {
                if (this.personalMenuState?.id) {
                    await this.destroyPersonalMenuFile();
                } else {
                    this.personalMenu.file = null;
                }
            },

            handleFileUpload($event) {
                const file = $event.target.files[0];

                if (file) {
                    const allowedTypes = ['application/pdf'];

                    if (allowedTypes.indexOf(file.type) === -1) {
                        this.$filters.toast(this.$t('errors.incorrectFileType'), { type: TYPE.ERROR });
                        return false;
                    }

                    this.personalMenu.file = file;
                }

                $event.target.value = '';
            },

            setInitialData() {
                this.order.period = this.individualSettings?.periods[0];
                this.order.menuType = this.menuTypes?.[0];

                if (this.personalMenuState) {
                    this.isUploadBlockVisible = false;
                }
            },

            async savePersonalMenu() {
                this.isPersonalMenuLoading = true;

                let formData = new FormData();

                const payload = {
                    link: this.personalMenu.link,
                    extra_information: this.personalMenu.extraInformation,
                };

                if (this.personalMenu.file) {
                    formData.append('file', this.personalMenu.file);
                }
                formData.append('payload', JSON.stringify(payload));

                try {
                    if (this.personalMenuState) {
                        formData.append('_method', 'PUT');
                        await this.updatePersonalMenu(formData);
                    } else {
                        await this.storePersonalMenu(formData);
                    }

                    this.order.orderableItem = this.personalMenuState;
                    this.isUploadBlockVisible = false;
                } catch (error) {
                    this.personalMenuErrors = error.errors;
                    this.$filters.toast(error.message);
                } finally {
                    this.isPersonalMenuLoading = false;
                }
            },

            async getMenuTypes() {
                try {
                    const response = await MenuTypeApi.getAll();
                    this.menuTypes = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            isMealKitShouldBeActive(mealKitId) {
                return (
                    this.order.orderableType === IndividualOrderableEnum.MEAL_KIT &&
                    this.order.orderableItem?.id === mealKitId
                );
            },
        },

        async mounted() {
            await this.getMenuTypes();
            this.setInitialData();
        },
    };
</script>

<style lang="scss" scoped>
    .title {
        display: none;
        margin-bottom: 20px;

        @include media($lg) {
            display: block;
        }
    }

    .order-kit__section {
        width: 100%;
        margin-top: 10px;
        padding-top: 10px;

        .tag-link {
            max-width: 130px;
        }

        h3 {
            margin-bottom: 10px;
        }

        h5 {
            color: $grey-form-label;
        }

        .order-kit__section__buttons-container {
            @include row-align-center;
            flex-wrap: wrap;

            .btn {
                margin-bottom: 10px;
                margin-right: 8px;
            }

            .personal-menu {
                margin-bottom: 20px;
            }
        }

        .radio {
            margin-bottom: 10px;
        }

        .input {
            margin: 20px 0;
        }

        .spin {
            width: 160px;
        }
    }

    .personal-menu__buttons {
        margin-bottom: 40px;
    }

    .file-upload {
        .upload-input {
            @include column-align-center-justify-center;
            position: relative;

            .file-icon {
                cursor: pointer;
                margin-bottom: 12px;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                opacity: 0;
                cursor: pointer;
            }
        }

        .tag__wrapper {
            @include column-align-start;

            .tag {
                max-width: 100%;

                margin-bottom: 10px;

                overflow: hidden;

                &:deep(.tag-container__text) {
                    width: 100%;

                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .underscored-link {
                    text-decoration: underline;
                }
            }
        }
    }

    .kit-personal-menu__section {
        // max-width: 400px;
    }

    .spin_section {
        margin-bottom: 30px;
    }

    .order-card__tag-container {
        @include row-align-start;
        flex-wrap: wrap;

        .btn {
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    // additions__footer
    .additions__footer {
        h3 {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .addition__inner {
            @include row-align-center-justify-between;

            width: 100%;

            .content {
                color: $primary;

                p {
                    font-size: $font-14;
                    margin-bottom: 10px;
                }
            }

            .img {
                width: 72px;
                height: 72px;

                background-image: url('../../../assets/images/backgrounds/default-addition-bg.png');

                box-shadow: $box-shadow-dark;
                border-radius: $br-10;
            }
        }
    }

    .footer-btn__wrapper {
        @include row-align-end-justify-end;

        border-top: solid $grey-line 1px;
        padding-top: 20px;
    }
</style>

<template>
    <div class="btn__wrapper">
        <CRButton
            class="btn"
            v-for="button in locales"
            :key="button"
            :active="locale === button"
            @click="handelSelectLocale(button)"
            pattern="section"
        >
            {{ button }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    export default {
        name: 'LangButtons',
        components: { CRButton },
        props: {
            locale: {
                type: String,
                default: '',
            },
        },
        emits: ['selectLocale'],
        data() {
            return {
                locales: ['lt', 'en'],
            };
        },
        methods: {
            handelSelectLocale(value) {
                this.$emit('selectLocale', value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .btn__wrapper {
        @include row-align-center;

        .btn {
            text-transform: uppercase;
        }

        .btn:first-child {
            margin-right: 8px;
        }
    }
</style>

import { IndividualOrderableEnum } from '@/utils/enums';
import {
    MACRONUTRIENTS_FORM,
    MEAL_NUMBER_FORM,
    ADDITION_DIET_PLAN_FORM,
} from '@/components/Order/Individual/IndividualOrderKit.vue';
import { mapActions } from 'vuex';

export const reorderIOMixin = {
    methods: {
        ...mapActions('individualOrder', ['setWholeIndividualOrder']),

        reorderIndividualOrder(order) {
            const payload = {
                order: {
                    period: order.period,
                    menuType: order.menuType,
                    caloriesAmount: order.caloriesAmount,
                    kitsAmount: order.kitsAmount,
                    additionalAllergen: order.additionalAllergen,
                    additionalDislikedProduct: order.additionalDislikedProduct,
                    otherRequest: order.otherRequest,
                    orderableType: order.orderableType,
                    orderableItem: order.resource,
                    macronutrients: order.macronutrients ?? { ...MACRONUTRIENTS_FORM },
                    additionalDietPlan: order.additionalDietPlan ?? { ...ADDITION_DIET_PLAN_FORM },
                    mealNumber: order.mealNumber ?? { ...MEAL_NUMBER_FORM },
                },
                personalMenu: order.orderableType === IndividualOrderableEnum.PERSONAL_MENU ? order.resource : null,
                allSelectedAdditionsState: {
                    allergens: order.allergens,
                    additions: order.additions,
                    dislikedProducts: order.dislikedProducts,
                    dietPlans: order.dietPlans,
                },
            };

            this.setWholeIndividualOrder(payload);

            this.$router.push({ name: 'individualOrderDelivery' });
        },
    },
};
